import React from "react";
import {BrowserView, MobileView} from 'react-device-detect';

export const About = (props) => {
  return (
  <div>
    <BrowserView>
      <div id="aboutUs">
        <div id="about">
          <div className="container">
            <div className="row">
              <div className="col-md-6 about-md">
                {" "}
                <div className="align-content-center">
                  <a href='https://www.ratujemyzwierzaki.pl/fundacjakundeldomek' title='RatujemyZwierzaki.pl - zbiórki charytatywne'>
                    <iframe frameborder='0' height='200px' scrolling='no' src='https://www.ratujemyzwierzaki.pl/organizacja/fundacjakundeldomek/banner' width='100%'></iframe>
                  </a>
                  <img src="img/about.png" style={{width: "100%"}} alt="" />{" "}
                </div>
              </div>
              <div className="col-md-6">
                <div className="about-text">
                  <h2>O Nas</h2>
                  <p>{props.data ? props.data.paragraph1 : "loading..."}</p>
                  <p>{props.data ? props.data.paragraph2 : "loading..."}</p>
                  <p style={{marginBottom: "0px"}}>{props.data ? props.data.paragraph3 : "loading..."}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BrowserView>
    <MobileView>
      <div id="aboutUs" className="about">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-s-12">
              {" "}
              <div className="align-content-center mb-30">
                <img src="img/about.png" style={{width: "100%"}} alt="" />{" "}
              </div>
            </div>
            <div className="col-xs-12 col-s-12 text-center">
              <div className="mb-30">
                <h2>O Nas</h2>
                <hr className="hr"></hr>
                <p>{props.data ? props.data.paragraph1 : "loading..."}</p>
                <p>{props.data ? props.data.paragraph2 : "loading..."}</p>
                <p>{props.data ? props.data.paragraph3 : "loading..."}</p>
              </div>

              <a href='https://www.ratujemyzwierzaki.pl/fundacjakundeldomek' title='RatujemyZwierzaki.pl - zbiórki charytatywne'>
                <iframe frameborder='0' height='200px' scrolling='no' src='https://www.ratujemyzwierzaki.pl/organizacja/fundacjakundeldomek/banner' width='80%'></iframe>
              </a>
            </div>
          </div>
        </div>
      </div>
    </MobileView>
  </div>
  );
};
