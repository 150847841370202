import React, { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import Config from "./config";

export const Gallery = (props) => {

  const [gallery, setGallery] = useState([]);

  const cfg = new Config();

  useEffect(async () => {
    const galleryResponse = await cfg.loadGallery();

    const images = galleryResponse.data
      .map(photo => {
        const photoUrl = cfg.buildUrl("gallery", photo);
        return {
          original: photoUrl,
          thumbnail: photoUrl
        };
      });

      setGallery(images);
  }, []);

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Galeria</h2>
        </div>
        <ImageGallery items={gallery} />
      </div>
    </div>
  );
};
