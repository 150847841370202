import React, { useEffect, useState } from "react";
import { BrowserView, MobileView } from 'react-device-detect';
import Config from "./config";

export const Testimonials = (props) => {

  const [charity, setCharity] = useState([]);

  const cfg = new Config();

  useEffect(async () => {
    const charityResp = await cfg.loadCharity();
    setCharity(charityResp.data.filter(x => !!x.visible));
  }, []);

  const render = (isMobile) => {
    return (
      <div className={isMobile ? "row auction-mobile" : "row auction"}>
        <div className={isMobile ? "col-sm-12" : "col-md-12 auctions-container"}>
          {charity.map((d, i) => (
            <div key={`${d.name}-${i}`}>
              <div className={isMobile ? "auction-mobile-container mb-30" : ""}>
                <div className="testimonial-image text-center">
                  <a href={d.link} target="_blank"><img className="auctions-img" src={cfg.buildUrl("charity", d.photo)} alt="" />{" "}<span className="auctions-text">{d.name}</span></a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  const renderMobile = () => {
    return (
      <MobileView>
        {render(true)}
      </MobileView>
    );
  }

  const renderDesktop = () => {
    return (
      <BrowserView>
        {render(false)}
      </BrowserView>
    );
  };

  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>Aktualne zbiórki</h2>
        </div>
        {renderDesktop()}
        {renderMobile()}
      </div>
    </div>
  );
};
