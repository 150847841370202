import React from "react";
import {BrowserView, MobileView} from 'react-device-detect';

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Jak mogę pomóc?</h2>
          <p>
            Możesz pomóc nam na wiele sposobów:
          </p>
        </div>
        <div className="row">
          <BrowserView>
            {props.data
              ? props.data.map((d, i) => (
                  <div key={`${d.name}-${i}`} className="col-md-4 height-300">
                    {" "}
                    <i className={d.icon}></i>
                    <div className="service-desc">
                      <h3>{d.name}</h3>
                      <p>{d.text}</p>
                      <p>{d.text2}</p>
                      <p>{d.text3}</p>
                      <p>{d.text4}</p>
                    </div>
                  </div>
                ))
              : "loading"}
          </BrowserView>
          <MobileView>
            {props.data
              ? props.data.map((d, i) => (
                  <div key={`${d.name}-${i}`} className="col-sx-12 col-s-12">
                    {" "}
                    <i className={d.icon}></i>
                    <div className="service-desc">
                      <h3>{d.name}</h3>
                      <p>{d.text}</p>
                      <p>{d.text2}</p>
                      <p>{d.text3}</p>
                      <p>{d.text4}</p>
                    </div>
                  </div>
                ))
              : "loading"}
          </MobileView>
        </div>
      </div>
    </div>
  );
};
