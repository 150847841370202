import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { BrowserView, MobileView } from 'react-device-detect';
import toast from 'react-hot-toast';

const emailJsCfg = {
  serviceId: 'service_t7amaqa',
  templateId: 'template_qe5lald',
  publicKey: 'o81ZHRxdxDoYvjTmf'
};

const reCaptcha = {
  siteKey: '6LdCiv4pAAAAADKKC8SM6utUw2iT6r8LJFoKuOw7'
};

const initialState = {
  name: "",
  email: "",
  message: "",
  sending: false
};
export const Contact = (props) => {
  const [{ name, email, message, sending }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({
    name: '',
    email: '',
    message: '',
    sending: false
  });

  const setSending = (sending) => {
    setState((prevState) => ({
      name: prevState.name,
      email: prevState.email,
      message: prevState.message,
      sending: sending
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setSending(true);

    toast.loading('Wysyłamy Twoją wiadomość...', { duration: 500 });

    {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }

    emailjs
      .sendForm(emailJsCfg.serviceId, emailJsCfg.templateId, e.target, emailJsCfg.publicKey)
      .then(
        (result) => {
          console.log(result.text);
          toast.success('Otrzymaliśmy Twoją wiadomość. Dziękujemy :)');
          clearState();
        },
        (error) => {
          setSending(false);
          toast.error('Nie udało nam się wysłać Twojej wiadomości :(');
          console.log(error.text);
        }
      );
  };
  return (
    <div>
      <div id="contact">
        <BrowserView>
          <div className="container">
            <div className="col-md-8">
              <div className="row">
                <div className="section-title">
                  <h2>Kontakt</h2>
                  <p>
                    Jeśli chcesz się z nami skontaktować, wypełnij formularz kontaktowy. Skontaktujemy się z Tobą w możliwie najszybszym terminie.
                  </p>
                </div>
                <form name="sentMessage" validate="true" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          placeholder="Imię"
                          required
                          value={name}
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Email"
                          required
                          value={email}
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Wiadomość"
                      required
                      value={message}
                      onChange={handleChange}
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div className="g-recaptcha" data-sitekey={reCaptcha.siteKey}></div>
                  <div id="success"></div>
                  <button type="submit" disabled={sending} className="btn btn-custom btn-lg">
                    Wyślij wiadomość
                  </button>
                </form>
              </div>
            </div>
            <div className="col-md-3 col-md-offset-1 contact-info">
              <div className="contact-item">
                <h3>Dane kontaktowe</h3>
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Adres
                  </span>
                  {props.data ? props.data.address : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Telefon
                  </span>{" "}
                  {props.data ? props.data.phone : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>{" "}
                  {props.data ? props.data.email : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    KRS: {props.data ? props.data.krs : "loading"}
                  </span>{" "}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    NIP: {props.data ? props.data.nip : "loading"}
                  </span>{" "}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    REGON: {props.data ? props.data.regon : "loading"}
                  </span>{" "}
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="social">
                  <ul>
                    <li>
                      <a href={props.data ? props.data.facebook : "/"}>
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href={props.data ? props.data.instagram : "/"}>
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </BrowserView>
        <MobileView>
          <div className="container text-center">
            <div className="col-md-8">
              <div className="row">
                <div className="section-title">
                  <h2>Kontakt</h2>
                  <p>
                    Jeśli chcesz się z nami skontaktować, wypełnij formularz kontaktowy. Skontaktujemy się z Tobą w możliwie najszybszym terminie.
                  </p>
                </div>
                <form name="sentMessage" validate="true" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          placeholder="Imię"
                          required
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Email"
                          required
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Wiadomość"
                      required
                      onChange={handleChange}
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div className="g-recaptcha" data-sitekey={reCaptcha.siteKey}></div>
                  <div id="success"></div>
                  <button type="submit" disabled={sending} className="btn btn-custom btn-lg">
                    Wyślij wiadomość
                  </button>
                </form>
              </div>
            </div>
            <div className="col-md-3 col-md-offset-1 contact-info">
              <div className="contact-item">
                <h3>Dane kontaktowe</h3>
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Adres
                  </span>
                  {props.data ? props.data.address : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Telefon
                  </span>{" "}
                  {props.data ? props.data.phone : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>{" "}
                  {props.data ? props.data.email : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    KRS: {props.data ? props.data.krs : "loading"}
                  </span>{" "}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    NIP: {props.data ? props.data.nip : "loading"}
                  </span>{" "}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    REGON: {props.data ? props.data.regon : "loading"}
                  </span>{" "}
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="social">
                  <ul>
                    <li>
                      <a href={props.data ? props.data.facebook : "/"}>
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href={props.data ? props.data.instagram : "/"}>
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </MobileView>
      </div>
      <div id="footer">
        <div className="container text-center">
          <a href="https://www.safe-animal.eu/pl/" target="_blank"><img src="img/safe-animal.jpg" alt="" /></a>
          <p><a href={require("../../src/assets/StatutKundelDomek.pdf")} download="StatutKundelDomek">Statut fundacji KundelDomek</a></p>
          <p>Fundacja KundelDomek 2024</p>
        </div>
      </div>
    </div>
  );
};
