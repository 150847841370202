import axios from "axios";

export default class Config {
    sas = 'sv=2022-11-02&ss=b&srt=o&sp=rt&se=9999-12-30T23:00:00Z&st=2024-06-21T22:00:00Z&spr=https&sig=cx%2B42%2BAiwtodIXS22ust0BJWmbjke%2FNhpCbTgsDrVl0%3D';
    storageUrl = 'https://kundeldomekresources.blob.core.windows.net/';
    galleryContainer = 'gallery';
    forAdoptionContainer = 'for-adoption';
    charityContainer = 'charity';

    constructor() {
        axios.interceptors.request.use((request) => {      
            request.headers.set({
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Request-Method": "GET,OPTIONS,HEAD"
            });

            return request;
          });
    }

    async loadGallery() {
        const url = `${this.storageUrl}${this.galleryContainer}/gallery.json?${this.sas}`;
        return await axios.get(url);
    }

    async loadCharity() {
        const url = `${this.storageUrl}${this.charityContainer}/charity.json?${this.sas}`;
        return await axios.get(url);
    }

    async loadForAdoption() {
        const url = `${this.storageUrl}${this.forAdoptionContainer}/for-adoption.json?${this.sas}`;
        return await axios.get(url);
    }

    buildUrl(container, blob) {
        return `${this.storageUrl}${container}/${blob}?${this.sas}`;
    }
}